import * as React from "react"
import Layout from "@/components/layout"
import SEO from "@/components/seo"
import style from './index.module.less'


const Index = () => {
    const list = [
        {
            title: 'Nebula for Android App',
            country: [
                'United States of America',
                'Japan (日本国)',
                'Korea (대한민국)',
                'United Kingdom',
                'Germany (Bundesrepublik Deutschland)',
                'French (République française)',
                'Spain (Reino de España)',
                'Italian (Repubblica italiana)',
                'Thailand (ราชอาณาจักรไทย)'
            ]
        },
        {
            title: 'Nebula for Mac/Windows App',
            country: [
                'United States of America',
                'Japan (日本国)',
                'Korea (대한민국)',
                'United Kingdom',
                'Germany (Bundesrepublik Deutschland)',
                'French (République française)',
                'Spain (Reino de España)',
                'Italian (Repubblica italiana)',
                'Thailand (ราชอาณาจักรไทย)'
            ]
        }
    ]
    return (
        <Layout menuBlack={true}>
            <SEO page="Nebula" />
            <div className={style.box}>
                <div className={style.title}>
                    Supported<br />
                    countries & regions
                </div>
                <div className={style.list}>
                    {list.map((item, index) => (
                        <div className={style.listItem} key={`nebula_supported_countries${index}`}>
                            <div className={style.listItemTitle}>{item.title}</div>
                            <div className={style.listItemContent}>
                                {item.country.map(country => (
                                    <div className={style.listItemContentCountry} key={`nebula_supported_countries${index}_${country}`}>
                                        {country}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>

            </div>


        </Layout>
    )
}

export default Index
